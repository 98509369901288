import "./App.css";
import React from "react";
import { routesConfig } from "./views/routes";
import { RouteObject, useRoutes } from "react-router-dom";
import { injectProject } from "./Infrastructure/modules/injectProject";
import { getProjects } from "./Infrastructure/modules/actions";
import { Service } from "./Infrastructure/modules/externalDtos";


const App = () => {
  const [config, setConfig] = React.useState([] as RouteObject[]);
  const [projects, setProjects] = React.useState([] as Service[]);
  const [loading, setLoading] = React.useState(false);
  
  React.useEffect(() => {
    if (projects.length === 0 && !loading){
      setLoading(true);
      getProjects()
        .then((results) => 
          {
            injectProject(results.services);
            setProjects(results.services);
            setConfig(routesConfig(results.services));
            setLoading(false);
          }
        );
    }
  }, [projects, loading])

  const AllRoutes = useRoutes(config);

  return (
    <div className="App">
      {AllRoutes}
    </div>
  );
}
export default App;
