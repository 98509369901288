import * as React from 'react';
import { getMember, getMemberName } from '../../Infrastructure/modules/actions';
import UnAuthorised from './UnAuthorized';
import { Page, PageProps } from '@mbrc/routing';
import { Spinner } from '@mbrc/spinner';

const AuthPageRoute = (props: PageProps) => {
  const [userData, setUserData] = React.useState(undefined as boolean | undefined)

  React.useEffect(() => {
    if (userData === undefined){
      getMember().then((res) => setUserData(res));
    }
  });

  return (
  <Page {...props}>
    {userData !== undefined ? <AuthComponent userData={userData} {...props}/> : <Spinner/>}
    </Page>
  )};

interface AuthComponentProps {
  userData: boolean;
  children: JSX.Element;
}

const AuthComponent = ({userData, children}: AuthComponentProps) => {
  const [user, setUser] = React.useState(undefined as string | undefined)

  React.useEffect(() => {
    if (user === undefined){
      getMemberName().then((res) => {
        setUser(res);
        setUserName(res);
      });
    }
  });

  if (userData) {
    return children;
  }            
  return (              
    <div className="alert-warning">
      <UnAuthorised/>
  </div>
  );
};

const setUserName = (user: string) => {
  const element = document.getElementById('user');
  if (element) {
    element.innerHTML = `${user}`;
  }
}

// const AuthError = (err: any) => {
//   if (err instanceof AuthenticationException) {
//     return (
//       <div className="alert alert-warning">
//         <p>Please log in to gain access to this function</p>
//       </div>
//     );
//   } else {
//     return (
//       <div className="alert alert-danger">
//         An error has occurred retrieving your details
//       </div>
//     );
//   }
// }

export default AuthPageRoute;
