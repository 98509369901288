import * as React from 'react';
import { Service } from 'src/Infrastructure/modules/externalDtos';
import Links from './Links';
import AuthPageRoute from './AuthPageRoute';

interface DynamicPageProps {
  service: Service;
}

const DynamicPage = ({service}: DynamicPageProps ) =>{
    document.getElementById(service.projectId)?.classList.add("active");
    return <AuthPageRoute title={service.projectName} applName={service.projectName}><Links service={service} /></AuthPageRoute>
}


export default DynamicPage;