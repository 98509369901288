import { CognitoAuth, CognitoAuthOptions, CognitoAuthSession } from 'amazon-cognito-auth-js';
import {
  AUTH_CLIENTID,
  AUTH_APPWEBDOMAIN,
  AUTH_REDIRECTURISIGNIN,
  AUTH_REDIRECTURISIGNOUT,
} from './appSettings';

import { ListenerContainer } from './listner';

type GlobalSessionListeners = ListenerContainer<{ signedIn: boolean }, {}>;
type SessionListeners = ListenerContainer<CognitoAuthSession, {}>;

interface AuthManagerProps {
  auth: CognitoAuth;
  listeners: SessionListeners;
}

interface CreateAuthManagerProps {
  checkParamToken: boolean;
  checkRefreshToken: boolean;
  callGlobalListeners: boolean;
}

/**
 * A class that interacts with the amazon auth library
 */
class AuthManager {
  static readonly GlobalListeners: GlobalSessionListeners = new ListenerContainer({
    successPropsChange: (oldProps, newProps) => oldProps.signedIn !== newProps.signedIn,
  });

  readonly listeners: SessionListeners;
  readonly cognitoAuth: CognitoAuth;

  constructor(props: AuthManagerProps) {
    this.cognitoAuth = props.auth;
    this.listeners = props.listeners;
  }

  /**
   * Create an instance of the class, and wait for setup to occur,
   * such as fetching the stored session and checking the query string.
   */
  static async Create(props: CreateAuthManagerProps) {
    const sessionListeners: SessionListeners = new ListenerContainer();
    const authData: CognitoAuthOptions = {
      ClientId: AUTH_CLIENTID,
      AppWebDomain: AUTH_APPWEBDOMAIN,
      TokenScopesArray: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      RedirectUriSignIn: AUTH_REDIRECTURISIGNIN,
      RedirectUriSignOut: AUTH_REDIRECTURISIGNOUT,
      AdvancedSecurityDataCollectionFlag: true,
    };

    const thisAuth = new CognitoAuth(authData);
    thisAuth.useCodeGrantFlow();

    // Causes the event listeners to be called whenever a session is fetched from cognito
    thisAuth.userhandler = {
      onSuccess: (session: any) => sessionListeners.succeedListeners(session),
      onFailure: (err: {}) => sessionListeners.failListeners(err),
    };

    if (props.callGlobalListeners) {
      sessionListeners.addListener({
        success: () => {
          this.GlobalListeners.succeedListeners({ signedIn: thisAuth.isUserSignedIn() });
        },
      });
    }

    // If the user has been redirected from the sign-in page, they'll have the "code" parameter
    const curUrl = window.location.href;
    if (props.checkParamToken && curUrl.includes('code=') && !thisAuth.isUserSignedIn()) {
      const sessionLoader = sessionListeners.addPromise().catch(() => {
        // Doesn't matter if there's an error here - it will usually mean they're
        // not signed in but have an old/invalid "code" parameter.
      });

      // The above will cause a listener to wait for the result of the below
      thisAuth.parseCognitoWebResponse(curUrl);
      await sessionLoader;
    }
    if (
      props.checkRefreshToken &&
      !thisAuth.isUserSignedIn() &&
      thisAuth.getSignInUserSession() &&
      thisAuth.getSignInUserSession().getRefreshToken() &&
      thisAuth
        .getSignInUserSession()
        .getRefreshToken()
        .getToken()
    ) {
      const sessionLoader = sessionListeners.addPromise().catch(() => {
        console.warn('Refresh failed');
        // Clear the tokens - stops this from failing each time they open
        thisAuth.clearCachedTokensScopes();
      });

      thisAuth.getSession();
      await sessionLoader;
    }

    return new AuthManager({
      auth: thisAuth,
      listeners: sessionListeners,
    });
  }

  /**
   * Create an `AuthManager`, do some work with it,
   * then call the `dispose()` function.
   *
   * @param props Props for `AuthManager.Create`
   * @param inner Perform tasks on `AuthManager` before disposed
   */
  static async UseTemporary<T>(
    props: CreateAuthManagerProps,
    inner: (authSession: AuthManager) => Promise<T>
  ) {
    const authSession = await AuthManager.Create(props);
    const retval = await inner(authSession);
    authSession.dispose();
    return retval;
  }

  /**
   * Get the signed-in session.
   *
   * If the user is not logged in, this will redirect them to
   * the login screen.
   *
   * If they are logged in, this will either get their session
   * from the internal cache, or refresh their session if it
   * has expired.
   *
   */
  async getSignedInSession() {
    const sPromise = this.listeners.addPromise();
    this.cognitoAuth.getSession();
    return await sPromise;
  }

  /**
   * Purposely refresh the session (using the refresh token)
   */
  async refreshSession() {
    const signedInSession = await this.getSignedInSession();
    try {
      const refreshToken = signedInSession.getRefreshToken().getToken();
      const prom = this.listeners.addPromise();
      this.cognitoAuth.refreshSession(refreshToken);
      return await prom;
    } catch {
      /**
       * Since the library we use is a bit crap, I've noticed an issue where:
       * - Initial token & refresh token are retrieved Day 1, 9.00am
       * - User signs in again, Day 2, 8.59am
       * - The JWTs expiry date is Day 2, 9.59am
       * - Refresh token is still set to expire on Day 2, 9.00am
       *
       * Therefore, the JWT is still valid, and works with the API, but the
       * user can not get a new JWT, as their refresh token is expired.
       * Hooooooooooooooopefully, this fixes that. I'd really hope so.
       */
      this.cognitoAuth.clearCachedTokensScopes();
      return await this.getSignedInSession();
    }
  }

  /**
   * Get rid of resources, such as any stray listeners.
   *
   * Garbage collector should do all of this anyways, so
   * it's mainly a precaution.
   */
  dispose() {
    this.listeners.removeAllListeners();
  }
}

/**
 * Base class, to be used for inheritance.
 *
 * Define methods here
 */
export abstract class AuthFactory {
  // TODO: Simplify
  readonly listeners = AuthManager.GlobalListeners;

  /**
   * When an AuthFactory is initialised, it will check localStorage
   * and perform important actions (such as checking the URL parameters).
   */
  abstract readonly finishLoading: Promise<void>;

  /**
   * Requires that a user is logged in and get their JWT
   */
  readJwt(): Promise<string> {
    return this.usingInnerAuth(async auth => {
      const session = await auth.getSignedInSession();
      return session.getIdToken().getJwtToken();
    });
  }

  /**
   * Reload the JWT and then return the new one
   */
  readFreshJwt(): Promise<string> {
    return this.usingInnerAuth(async auth => {
      const session = await auth.refreshSession();
      // console.log(session.getIdToken().getJwtToken())
      return session.getIdToken().getJwtToken();      
    });
  }

  /**
   * Gets the expiry date for the current JWT
   */
  getExpiry(): Promise<Date> {
    return this.usingInnerAuth(async auth => {
      const session = await auth.getSignedInSession();
      const expiration = session.getIdToken().getExpiration();
      return new Date(expiration * 1000);
    });
  }

  // TODO: Client token validation

  isSignedIn() {
    return this.usingInnerAuth(async auth => {
      return auth.cognitoAuth.isUserSignedIn();
    });
  }

  signIn() {
    return this.usingInnerAuth(async auth => {
      try {
        await auth.getSignedInSession();
      } catch {
        // I noticed an issue where a user has invalid session tokens, and
        // can't sign in at all unless they manually cleared localStorage.
        // This fixes that issue.

        // Note: This might not be an issue anymore due to the "checkRefreshToken"
        // prop in AuthManager.
        auth.cognitoAuth.clearCachedTokensScopes();
        await auth.getSignedInSession();
      }
    });
  }


  signOut() {
    return this.usingInnerAuth(async auth => {
      auth.cognitoAuth.signOut();
    });
  }

  trySignOut() {
    return this.usingInnerAuth(async auth => {
      const isSignedIn = auth.cognitoAuth.isUserSignedIn();
      if (isSignedIn) {
        auth.cognitoAuth.signOut();
      } else {
        this.listeners.succeedListeners({ signedIn: false });
      }
    });
  }

  /**
   * This causes all the listeners to be called.
   *
   * Mainly for if the user has signed in/out, and the current page
   * has not updated.
   */
  checkListeners() {
    return this.usingInnerAuth(async auth => {
      this.listeners.succeedListeners({ signedIn: auth.cognitoAuth.isUserSignedIn() });
    });
  }

  /**
   * An overridable method to get the underlying `AuthManager` object.
   * For the `SingletonAuthFactory`, it will use the singular `AuthManager`.
   * For the `ScopedAuthFactory`, it will create a new `AuthManager`, perform
   * the `inner()` function, then dispose the `AuthManager`.
   *
   * Because of the latter, it's best to batch as many operations as you can
   * in to here.
   */
  protected abstract usingInnerAuth<T>(inner: (auth: AuthManager) => Promise<T>): Promise<T>;
}

/**
 * This will reuse the underlying AuthManager for each operation.
 */
export class SingletonAuthFactory extends AuthFactory {
  readonly finishLoading: Promise<void>;

  constructor() {
    super();
    this.memoryAuth = AuthManager.Create({
      checkParamToken: true,
      checkRefreshToken: true,
      callGlobalListeners: true,
    });
    this.finishLoading = this.memoryAuth.then(() => {
      return;
    });
  }

  private readonly memoryAuth: Promise<AuthManager>;

  protected async usingInnerAuth<T>(inner: (auth: AuthManager) => Promise<T>): Promise<T> {
    await this.finishLoading;
    return inner(await this.memoryAuth);
  }
}

/**
 * This will create+destroy the underlying AuthManager for each operation.
 *
 * This one will work better when users have multiple tabs open, as it
 * re-initiates from localStorage each time, rather than keeping data
 * in memory.
 * Therefore, if they have one tab open, sign out, then try and perform
 * an auth action in another (previously signed-in) tab, it won't keep
 * their session.
 */
export class ScopedAuthFactory extends AuthFactory {
  readonly finishLoading = AuthManager.UseTemporary(
    {
      checkParamToken: true,
      checkRefreshToken: true,
      callGlobalListeners: true,
    },
    async () => {
      return;
    }
  );

  protected async usingInnerAuth<T>(inner: (auth: AuthManager) => Promise<T>): Promise<T> {
    await this.finishLoading;
    return await AuthManager.UseTemporary(
      {
        checkParamToken: true,
        checkRefreshToken: true,
        callGlobalListeners: true,
      },
      inner
    );
  }
}

// Note: By creating a factory, it performs the check for the parameters
export const authFactory: AuthFactory = new ScopedAuthFactory();
