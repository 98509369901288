import * as React from 'react';
import { Service } from "../../Infrastructure/modules/externalDtos";
import { ADMIN_BASE_NAME, ADMIN_BASE_URL } from '../../Infrastructure/modules/appSettings';

interface Projects {
  projects: any;
}

const Home = (props: Projects) => {
  const output = document.getElementById("content");
  if (output){
    output.innerHTML = 
    "<p>Welcome to the administration area for MBRC web services.</p>";
    const navMenuItem = document.createElement('ul');
    const transformed = props.projects.services.map(
      (p:Service) =>
      `<li><a href="${ADMIN_BASE_URL}/${ADMIN_BASE_NAME && `${ADMIN_BASE_NAME}/`}${p.projectId}">${p.projectName}</a></li>`
    );
    navMenuItem.innerHTML = transformed.join('');
    output.appendChild(navMenuItem);
    output.innerHTML+=
    `<h3>Other actions</h3>
     <p>In order to update your email address / password, sign in to <a href="https://www.moretonbay.qld.gov.au/Membership">My Moreton Bay</a></p>`;
  }                  
  return <></>;
};

export default Home;
