import { Service } from "../../Infrastructure/modules/externalDtos";
import { ADMIN_BASE_NAME, ADMIN_BASE_URL } from "./appSettings";

export function injectProject(services: Service[]) {
  services.forEach((service: Service, i: number) => {
    const navMenuItem = document.createElement("li");
    navMenuItem.className = "nav-item";
    navMenuItem.id = service.projectId;
    navMenuItem.innerHTML = `
      <a href="${ADMIN_BASE_URL}/${ADMIN_BASE_NAME && `${ADMIN_BASE_NAME}/`}${service.projectId}" class="nav-link">
      <i class="nc-icon nc-settings-gear-64"></i><span>${service.projectName}</span></a></a>        
      `;
    document.getElementsByClassName("nav")[0].appendChild(navMenuItem);
  });
}
