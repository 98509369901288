import { FetchException } from "./exceptions";
import { authFactory } from "./auth";
import jwt_decode from "jwt-decode";

const assertOk = async (resp: Response) => {
  if (!resp.ok && resp.status !== 1223) {
    // 1223 checks for <IE10 issue
    throw new FetchException(resp);
  } else {
    return resp;
  }
};

const parseResponse = async (resp: Response) => {
  if (resp.status === 204 || resp.status === 1223) {
    return {};
  }
  return resp
    .text()
    .then((text) => {
      try {
        return JSON.parse(text);
      } catch {
        return text;
      }
    })
    .catch(() => ({}));
};

type Method = "GET" | "POST" | "PUT" | "DELETE";

interface FetchProps {
  url: string;
  method: Method;
  body?: {};
}

interface AuthFetchProps extends FetchProps {
  reloadJwtBefore?: boolean;
  reloadJwtAfter?: boolean;
}

export const fetchAuthorised = async ({
  url,
  method,
  body,
  reloadJwtBefore,
  reloadJwtAfter,
}: AuthFetchProps) => {
  const tryFetch = async (jwtString: string) => {
    const headers = new Headers({
      Authorization: "bearer " + jwtString,
    });

    const init: RequestInit = {
      mode: "cors",
      method,
      headers,
    };

    if (body) {
      headers.append("Content-Type", "application/json");
      init.body = JSON.stringify(body);
    }

    return fetch(url, init);
  };

  return tryFetch(
    await (reloadJwtBefore ? authFactory.readFreshJwt() : authFactory.readJwt())
  )
    .then(async (resp) => {
      // TODO: We might not need this retry anymore
      if (resp.status === 401) {
        return tryFetch(await authFactory.readFreshJwt());
      } else {
        return resp;
      }
    })
    .then(assertOk)
    .then(parseResponse)
    .then(async (resp) => {
      if (reloadJwtAfter) {
        await authFactory.readFreshJwt();
      }
      return resp;
    });
};

export const memberName = async () => {
  let token = await authFactory.readFreshJwt();
  // console.log(token);
  let decode: any = jwt_decode(token);
  return decode["given_name"];
};

export const memberDetails = async () => {
  let token = await authFactory.readFreshJwt();
  let decode: any = jwt_decode(token);
  // console.log(decode["given_name"])
  const cognitoGroups = decode["cognito:groups"];
  const email = decode["email"];

  for (var index in cognitoGroups) {
    if (cognitoGroups[index].includes("Admin")) {
      return true;
    }
  }

  if (email.includes("@moretonbay.qld.gov.au")){
    return true;
  }

  return false;
};

interface FetchProps {
  url: string;
  method: Method;
  body?: {};
}

export const fetchUnauthorised = async ({ url, method, body }: FetchProps) => {
  const init: RequestInit = {
    mode: 'cors',
    method,
  };

  if (body) {
    init.headers = new Headers({
      'Content-Type': 'application/json',
    });
    init.body = JSON.stringify(body);
  }

  return fetch(url, init)
    .then(assertOk)
    .then(parseResponse);
};
