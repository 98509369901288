import { LogOut } from "react-feather";
import { Modal } from "react-bootstrap";
import React from "react";
import { authFactory } from "../../Infrastructure/modules/auth";
import "./UnAuthorized.css";

const UnAuthorized = () => {
  return (
    <>
      <div className="modal-dialog">
        <Modal
          show={true}
          onHide={() => {
            authFactory.signOut();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Unauthorized</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center">
                  <h1 className="h2">Access Denied</h1>
                  <p className="lead">This function is for Admin users only.</p>
                  <a
                    href="#"
                    className="btn-lg"
                    style={{ borderStyle: "solid" }}
                    onClick={() => {
                      authFactory.signOut();
                    }}
                  >
                    <LogOut color="grey" size="20" />
                    <i className="align-middle mr-1" data-feather="log-out"/>
                    Log out
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer/>
        </Modal>
      </div>
    </>
  );
};

export default UnAuthorized;
