import * as React from "react";
import Home from "./Home";
import { getProjects } from "../../Infrastructure/modules/actions";
import { Service } from "../../Infrastructure/modules/externalDtos";
import { Navigate, RouteObject } from "react-router-dom";
import { PromiseWaiter } from "@mbrc/promise-waiter";
import { Spinner } from "@mbrc/spinner";
import DynamicPage from "../components/DynamicPage";
import AuthPageRoute from "../components/AuthPageRoute";


export const routesConfig = (projects: Service[]): RouteObject[] => {
  const dynamicRoutes = Array.isArray(projects) ? projects.map(service => {
    return{
      element: <DynamicPage service={service} />,
      path: `/${service.projectId}/*`
    } as RouteObject;
  }) : [];

  dynamicRoutes.unshift({
    element: <Navigate to="/" />,
    path: "/admin"}, 
    {
      element: <AuthPageRoute applName="" title="">
        <PromiseWaiter spinner={Spinner} promise={getProjects}>
          {(response) => {
            document.getElementById("home")?.classList.add("active");
            return (
              <Home projects={response} />
            )
          }}
        </PromiseWaiter>
      </AuthPageRoute>,
      path: "/"})
      
  return dynamicRoutes;
}

