import * as React from "react";
import { Service } from "../../Infrastructure/modules/externalDtos";
import { getManifest } from "../../Infrastructure/modules/actions";

interface LinksProps {
  service: Service;
}

const Links = ({service}: LinksProps) => {
  if (service.menifestAddress === "") {
    AddScript(service.jsAddress);
    AddLink(service.cssAddress);
  } else {
    const basePath = service.menifestAddress.substring(
      0,
      service.menifestAddress.lastIndexOf("/")
    );

    getManifest(service.menifestAddress)
      .then(entry =>
        {
          return entry.entrypoints.forEach((url: string, i: number) => {
          if (url.endsWith("js")) {
            AddScript(`${basePath}/${url}`);
          } else {
            AddLink(`${basePath}/${url}`);
          }
        })}
      );
  }

  return <div id="admin-panel" />;
};

const AddScript = (url: string) => {
  const script = document.createElement("script");
  script.src = url;
  script.defer = true;
  document.body.appendChild(script);
};

const AddLink = (url: string) => {
  const link = document.createElement("link");
  link.href = url;
  link.rel = "stylesheet";
  document.body.appendChild(link);
};

export default Links;
