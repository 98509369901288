/* eslint-disable */
export type ListenerId = number;
// type ListenerContainerId = string;

interface ListenerEntry<SuccessProps, FailProps> {
  id: ListenerId;
  success: (props: SuccessProps) => void;
  error?: (props: FailProps) => void;
  compare: boolean;
  hasRun: boolean;
  removeOnRun: boolean;
}

interface NewListenerProps<SuccessProps, FailProps> {
  success: (props: SuccessProps) => void;
  error?: (props: FailProps) => void;
  compare?: boolean;
}

type CompareFn<P> = (oldProps: P, newProps: P) => boolean;

interface ListenerContainerProps<S, F> {
  successPropsChange?: CompareFn<S>;
  errorPropsChange?: CompareFn<F>;
  catchErrors?: boolean;
}

export class ListenerContainer<SuccessProps, FailProps> {
  constructor(props?: ListenerContainerProps<SuccessProps, FailProps>) {
    this.listeners = [];
    this.successPropsChange =
      (props && props.successPropsChange) || ((oldProps, newProps) => oldProps !== newProps);
    this.errorPropsChange =
      (props && props.errorPropsChange) || ((oldProps, newProps) => oldProps !== newProps);
    this.catchErrors = (props && props.catchErrors) || false;
    // this.containerId = ListenerContainer.GenerateContainerId();

    this.logStuff('Creating listener container');
  }

  // private readonly containerId: ListenerContainerId = Math.random()
  //   .toString(36)
  //   .substring(7);

  private listeners: ListenerEntry<SuccessProps, FailProps>[] = [];
  private lastSuccessProps: SuccessProps | null = null;
  private lastFailProps: FailProps | null = null;

  private numSuccesses = 0;
  private numFails = 0;

  private readonly successPropsChange: CompareFn<SuccessProps>;
  private readonly errorPropsChange: CompareFn<FailProps>;

  private readonly catchErrors: boolean;

  addListener(props: NewListenerProps<SuccessProps, FailProps>) {
    const id = this.generateListenerId();
    this.listeners.push({
      id,
      success: props.success,
      error: props.error,
      compare: props.compare || false,
      hasRun: false,
      removeOnRun: false,
    });
    return id;
  }

  removeListener(listenerId: ListenerId) {
    this.listeners = this.listeners.filter(l => l.id !== listenerId);
  }

  removeListeners(listenerIds: ListenerId[]) {
    this.listeners = this.listeners.filter(l => listenerIds.indexOf(l.id) < 0);
  }

  removeAllListeners() {
    this.logStuff('Removing all listeners', {
      numListeners: this.listeners.length,
      successes: this.numSuccesses,
      fails: this.numFails,
    });
    this.listeners = [];
  }

  addOnceOff(props: NewListenerProps<SuccessProps, FailProps>) {
    const id = this.generateListenerId();
    this.listeners.push({
      id,
      success: props.success,
      error: props.error,
      compare: false,
      hasRun: false,
      removeOnRun: true,
    });
  }

  addPromise() {
    return new Promise<SuccessProps>((success, error) => {
      this.addOnceOff({ success, error });
    });
  }

  succeedListeners(props: SuccessProps, compareOverride?: boolean) {
    this.logStuff('Succeeding listeners');
    const propsChanged = this.lastSuccessProps
      ? compareOverride !== undefined
        ? compareOverride
        : this.successPropsChange(this.lastSuccessProps, props)
      : true;

    const removeList: ListenerId[] = [];
    // const onceOffs: ListenerEntry<SuccessProps, FailProps>[] = [];
    // for (let i = 0; i < this.listeners.length; i++) {
    // }

    for (let i = 0; i < this.listeners.length; i++) {
      const listener = this.listeners[i];
      if (listener.compare === false || propsChanged || !listener.hasRun) {
        if (this.catchErrors) {
          try {
            listener.success(props);
          } catch (ex) {
            // console.warn(ex);
          }
        } else {
          listener.success(props);
        }
        this.numSuccesses++;
        listener.hasRun = true;
        if (listener.removeOnRun) {
          removeList.push(listener.id);
        }
      }
    }

    this.removeListeners(removeList);
  }

  failListeners(props: FailProps, compareOverride?: boolean) {
    this.logStuff('Failing listeners');
    const propsChanged = this.lastFailProps
      ? compareOverride !== undefined
        ? compareOverride
        : this.errorPropsChange(this.lastFailProps, props)
      : true;

    const removeList: ListenerId[] = [];

    for (let i = 0; i < this.listeners.length; i++) {
      const listener = this.listeners[i];
      if (listener.error && (listener.compare === false || propsChanged || !listener.hasRun)) {
        if (this.catchErrors) {
          try {
            listener.error(props);
          } catch (ex) {
            // console.warn(ex);
          }
        } else {
          listener.error(props);
        }
        this.numFails++;

        listener.hasRun = true;
        if (listener.removeOnRun) {
          removeList.push(listener.id);
        }
      }
    }

    this.removeListeners(removeList);
  }

  private generateListenerId() {
    let id = -1;
    do {
      id = Math.floor(Math.random() * 100000);
    } while (this.listeners.findIndex(f => f.id === id) >= 0);
    return id;
  }

  private logStuff(message: string, info?: { [key: string]: {} }) {
    // const logObj = {
    //   containerId: this.containerId,
    //   message,
    //   ...info,
    // };
    // // tslint:disable-next-line:no-console
    // console.log(logObj);
  }
}
