import "core-js/es";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouterProps, BrowserRouter } from "react-router-dom";
import { ADMIN_BASE_NAME } from "./Infrastructure/modules/appSettings";
// import LoginButton from "./views/components/LoginButton";

const Router = (props: BrowserRouterProps) => {
  return <BrowserRouter {...props} basename={ADMIN_BASE_NAME} />;
};

ReactDOM.render(<Router><App /></Router>, document.getElementById("dashboard-panel") as HTMLElement);
